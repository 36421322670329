<template>
    <!-- Content Wrapper. Contains page content -->
    <div class="content-wrapper">
        <BreadCrumb :segments="segments" :page-name="pageName"></BreadCrumb>
        <!-- Main content -->
        <section class="content pl-2 pr-2">
            <CustomDataTable v-bind:tableDataUrl="tableDataUrl" v-bind:columns="columns" v-bind:searchFilters="searchFilters" v-bind:additionalButtons="additionalButtons" ref="mainDataTable"></CustomDataTable>
        </section>
        <!-- /.content -->
        <ModalGeneral :title="printModal.title" size="modal-lg" :showModal="printModal.showModal" :hideModal="hidePrintModal" v-if="printModal.showModal">
            <div slot="modal-body">
                <print-form :form-type="printModal.formType" :form-category="printModal.formCategory" :id="printModal.id"></print-form>
            </div>
        </ModalGeneral>
    </div>
</template>

<script>
    import Vue from 'vue';
    import dayjs from 'dayjs';
    import customParseFormat from 'dayjs/plugin/customParseFormat';
    dayjs.extend(customParseFormat);

    import BreadCrumb from "../../../components/app/common/BreadCrumb";
    import CustomDataTable from "../../../components/app/table/CustomDataTable";
    import CustomDataTableDataConditionButton from "../../../components/app/table/CustomDataTableDataConditionButton";
    import ModalGeneral from "../../../components/app/dialog/ModalGeneral";
    import PrintForm from "../../../components/app/common/PrintForm";
    import {getPermissionByKey} from "@/helpers/userHelper";
    import CustomDataTableDropDownButton from "@/components/app/table/CustomDataTableDropDownButton";
    import {PurchaseService} from "@/services/purchase-service";
    import {convertUTCToLocalWithFormat} from "@/helpers/dateHelper";
    let purchaseService = new PurchaseService();
    export default {
        name: 'Purchases_view',
        data: function(){
            return {
                pageName: 'Purchases',
                segments: [
                    { link: false, text: 'Purchases' },
                ],
                purchase: {},
                tableDataUrl: this.$globalSettings.api.endpoints.purchase.purchasesDatatable,
                columns: [
                    {label: 'ID', name: 'id', orderable: true, style: 'width: 60'},
                    {label: 'Amount', name: 'grand_total', orderable: true},
                    {label: 'Date', name: 'date', orderable: true, transform: ({data, name}) => convertUTCToLocalWithFormat(data[name]) },
                    {label: 'Ref#', name: 'ref', orderable: true},
                    {label: 'Supplier', name: 'supplier_name', orderable: true},
                    {label: 'Type', name: 'type', orderable: true, transform: ({data, name}) => Object.keys(this.$globalEnums.formCategories).find(key => this.$globalEnums.formCategories[key] === data[name])},
                    {label: '', orderable: false, classes: {'btn': true, 'btn-outline-secondary': true, 'btn-sm': true,}, style: 'width: 62px', event: "click", handler: this.print, meta: { label: 'View', showLabel: false, iconClass: 'fas fa-search', dataPermissionConditions: [{column: 'type', val: this.$globalEnums.formCategories.Invoice, permission: 'purchase_invoice_view'}, {column: 'type', val: this.$globalEnums.formCategories.Return, permission: 'purchase_return_view'}], editableCheck: false }, component: CustomDataTableDataConditionButton },
                    {
                        label: '',
                        style: 'width: 60px',
                        component: CustomDataTableDropDownButton,
                        meta: {
                            items: [
                                {
                                    label: 'Edit',
                                    showLabel: true,
                                    iconClass: 'fas fa-edit',
                                    event: "click",
                                    handler: this.edit,
                                    dataPermissionConditions: [
                                        {column: 'type', val: this.$globalEnums.formCategories.Order, permission: 'purchase_order_edit'},
                                        {column: 'type', val: this.$globalEnums.formCategories.Invoice, permission: 'purchase_invoice_edit'},
                                        {column: 'type', val: this.$globalEnums.formCategories.Return, permission: 'purchase_return_edit'}
                                    ],
                                    conditions: [],
                                },
                                {
                                    label: 'Payments',
                                    showLabel: true,
                                    iconClass: 'fas fa-dollar-sign',
                                    event: "click",
                                    handler: this.payments,
                                    dataPermissionConditions: [
                                        {column: 'type', val: this.$globalEnums.formCategories.Invoice, permission: this.$globalSettings.permissions.payment.payment_list},
                                        {column: 'type', val: this.$globalEnums.formCategories.Return, permission: this.$globalSettings.permissions.payment.payment_list}
                                    ],
                                    conditions: [],
                                },
                                {
                                    label: 'Create Return',
                                    showLabel: true,
                                    iconClass: 'fas fa-undo',
                                    event: "click",
                                    handler: this.createReturnFromInvoice,
                                    dataPermissionConditions: [
                                        {column: 'type', val: this.$globalEnums.formCategories.Invoice, permission: 'purchase_return_create'},
                                    ],
                                    conditions: [],
                                },
                                {
                                    label: 'Delete',
                                    showLabel: true,
                                    iconClass: 'fas fa-trash',
                                    event: "click",
                                    handler: this.delete,
                                    dataPermissionConditions: [
                                        {column: 'type', val: this.$globalEnums.formCategories.Order, permission: 'purchase_order_delete'},
                                        {column: 'type', val: this.$globalEnums.formCategories.Invoice, permission: 'purchase_invoice_delete'},
                                        {column: 'type', val: this.$globalEnums.formCategories.Return, permission: 'purchase_return_delete'}
                                    ],
                                    conditions: [],
                                }
                            ]
                        }
                    },
                ],
                searchFilters:{
                    search: '',
                    length: 10,
                    column: 'date',
                    dir: 'desc',
                    type: -1
                },
                additionalButtons: [],

                printModal:{
                    title: 'Print',
                    showModal: false,
                    formType: this.$globalEnums.formTypes.Purchase,
                    formCategory: 1,
                    id: null
                }
            }
        },
        props: {},
        components: {
            PrintForm,
            ModalGeneral,
            BreadCrumb, CustomDataTable
        },
        computed: {

        },
        methods: {
            invoice(){
                this.$router.push({name: 'App.Purchase', params: { id: 0, type: this.$globalEnums.formCategories.Invoice }});
            },
            return(){
                this.$router.push({name: 'App.Purchase', params: { id: 0, type: this.$globalEnums.formCategories.Return }});
            },
            edit(data){
                this.$router.push({name: 'App.Purchase', params: { id: data.id }});
            },
            payments(data){
                this.$router.push({name: 'App.Payments.Invoice', params: { id: data.id, type: data.type === this.$globalEnums.formCategories.Invoice? this.$globalEnums.paymentTypes.Purchase: this.$globalEnums.paymentTypes.PurchaseReturn }});
            },
            async delete(data) {
                if (confirm("Are you sure that you want to delete this purchase?")) {
                    let loader = this.$loading.show();
                    let response = await purchaseService.deletePurchase(data.id);
                    if (response.isSuccess) {
                        this.$refs.mainDataTable.loadTable();
                    } else {
                        Vue.$toast.open({message: response.message, type: 'error'});
                    }
                    loader.hide();
                }
            },
            createReturnFromInvoice(data){
                this.$router.push({name: 'App.Purchase', params: { id: 0, type: this.$globalEnums.formCategories.Return, refForm: { id: data.id, type: this.$globalEnums.formCategories.Invoice } }});
            },
            print(data){
                //server "Type" == "frontend FrmCategory"
                this.printModal.formCategory = data.type;
                this.printModal.showModal = true;
                this.printModal.id = data.id;
            },
            hidePrintModal(){
                this.printModal.showModal = false;
            }
        },
        mounted() {
            if (getPermissionByKey(this.$globalSettings.permissions.purchase.purchase_return_create)) {
                this.additionalButtons.push({
                    label: 'Return',
                    buttonClass: 'btn btn-outline-secondary btn-sm float-right',
                    labelIconClass: 'fas fa-plus',
                    click: this.return
                });
            }
            if (getPermissionByKey(this.$globalSettings.permissions.purchase.purchase_invoice_create)) {
                this.additionalButtons.push({
                    label: 'Invoice',
                    buttonClass: 'btn btn-outline-secondary btn-sm float-right',
                    labelIconClass: 'fas fa-plus',
                    click: this.invoice
                });
            }
        }
    }
</script>

<style scoped>

</style>
