<template>
    <div>
        <div class="modal-backdrop fade" :class="visible? 'show': 'hide'" v-cloak :style="visible? '': 'display: none;'"></div>
        <div class="modal fade" id="modal-default" :class="visible? 'show': 'hide'" v-cloak :style="visible? 'display: block; padding-right: 17px;': 'display: none;'">
            <div class="modal-dialog" :class="size">
                <div class="modal-content">
                    <div class="modal-header border-bottom-0 pl-4 pt-4">
                        <h5 class="m-0 heading-border-bottom"><i :class="titleIcon" v-if="titleIcon"></i> {{ title }}</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close" v-on:click="hideModal">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <slot name="modal-body"></slot>
                    </div>
                    <div class="modal-footer justify-content-between" v-if="showFooter">
                        <slot name="modal-footer">
                            <button type="button" class="btn btn-default" data-dismiss="modal" v-on:click="hideModal" v-if="showFooterCloseBtn">Close</button>
                        </slot>

                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "ModalGeneral",
        data: function() {
            return {
                isVisible: false
            }
        },
        props: {
            showModal:  {
                type: Boolean,
                default: () => false
            },
            size:  {
                type: String,
                default: () => 'modal-md'
            },
            titleIcon:{
                type: String,
                default: () => null
            },
            title:  {
                type: String,
                default: () => ''
            },
            hideModal: {
                type: Function,
                default:() => {}
            },
            showFooter: {
                type: Boolean,
                default: () => false
            },
            showFooterCloseBtn: {
                type: Boolean,
                default: () => false
            }
        },
        computed: {
            visible: function () {
                return this.showModal;
            }
        }
    }
</script>

<style scoped>
    .modal-body{
        max-height: 80vh;
        overflow-y: scroll;
    }
</style>